import React from 'react';

import { QueryClient, QueryClientConfig } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

import App from 'app/App';
import AppProvider from 'context/AppProviders';

import 'i18n/i18n';
import 'styles/global.scss';

const queryConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      // gcTime: 0,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
};

const queryClient = new QueryClient(queryConfig);

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <HashRouter>
      <AppProvider queryClient={queryClient} withQueryDevTools>
        <App />
      </AppProvider>
    </HashRouter>
  </React.StrictMode>
);
