import React from 'react';

import { useTranslation } from 'react-i18next';

type SquareLoaderProps = {
  /** invertion des couleurs */
  invert?: boolean;
};

const SquareLoader = ({ invert }: SquareLoaderProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={`square-loader ${invert ? 'invert' : ''}`}>
      <div className="outer">
        <div className="inner"></div>
      </div>
      <span className="description">{t('loading')}</span>
    </div>
  );
};

export default SquareLoader;
