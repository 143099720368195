import { authorities, Authority } from 'enum/authorities';

export type Role = keyof typeof roles;
export const roles = {
  CLIENT_ADMINISTRATEUR: 'CLIENT_ADMINISTRATEUR',
  CLIENT_CONSULTATION: 'CLIENT_CONSULTATION',
  CLIENT_MANAGER: 'CLIENT_MANAGER',
  CLIENT_SIMPLE: 'CLIENT_SIMPLE',
  CONSTRUCTEUR_ADMINISTRATEUR: 'CONSTRUCTEUR_ADMINISTRATEUR',
  CONSTRUCTEUR_CONSULTATION: 'CONSTRUCTEUR_CONSULTATION',
} as const;

export const traductionsRoles = {
  [roles.CLIENT_ADMINISTRATEUR]: 'roles.client_admin',
  [roles.CLIENT_MANAGER]: 'roles.client_manager',
  [roles.CLIENT_CONSULTATION]: 'roles.client_consultation',
  [roles.CLIENT_SIMPLE]: 'roles.client_simple',
  [roles.CONSTRUCTEUR_ADMINISTRATEUR]: 'roles.constructeur_admin',
  [roles.CONSTRUCTEUR_CONSULTATION]: 'roles.constructeur_consultation',
};

export const rolesAuthorities: {
  readonly [key in Role]: readonly Authority[];
} = {
  [roles.CLIENT_ADMINISTRATEUR]: [
    authorities.WRITE,
    authorities.ADMINISTRATION_CLIENTS,
    authorities.ADMINISTRATION_UTILISATEURS,
  ],
  [roles.CLIENT_CONSULTATION]: [],
  [roles.CLIENT_MANAGER]: [
    authorities.WRITE,
    authorities.ADMINISTRATION_UTILISATEURS,
  ],
  [roles.CLIENT_SIMPLE]: [authorities.WRITE],
  [roles.CONSTRUCTEUR_ADMINISTRATEUR]: [
    authorities.WRITE,
    authorities.ADMINISTRATION_CLIENTS,
    authorities.ADMINISTRATION_UTILISATEURS,
    authorities.SUPER_ADMIN,
  ],
  [roles.CONSTRUCTEUR_CONSULTATION]: [],
};
