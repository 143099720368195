export const optionsNbLignesTableau = [
  {
    libelle: 'my_account.preferences.nb_lignes.25',
    value: 25,
  },
  {
    libelle: 'my_account.preferences.nb_lignes.50',
    value: 50,
  },
];
