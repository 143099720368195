import { useSnackbar as useSnackbarNotistack } from 'notistack';
import { useTranslation } from 'react-i18next';

export function useSnackbar() {
  const { t } = useTranslation();
  const { closeSnackbar, enqueueSnackbar } = useSnackbarNotistack();

  return {
    closeSnackbar,
    error: (msg: string, options = {}): void => {
      enqueueSnackbar(t(msg, options), { persist: true, variant: 'error' });
    },
    success: (msg: string): void => {
      enqueueSnackbar(t(msg), { variant: 'success' });
    },
  };
}
