import React from 'react';

import { useUser } from 'api/account';
import SquareLoader from 'components/loader/SquareLoader';
import { createUserInfos, User } from 'utils/usersUtils';

type AuthContextProps = User | null;

const AuthContext = React.createContext<AuthContextProps>(
  {} as AuthContextProps
);
AuthContext.displayName = 'AuthContext';

const AuthProvider = (props: React.PropsWithChildren) => {
  const { data, isLoading } = useUser();

  if (isLoading) {
    return <SquareLoader />;
  }

  return <AuthContext.Provider value={createUserInfos(data)} {...props} />;
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

function useUserInfos(): User {
  const user = useAuth();
  if (user === null) {
    throw new Error(
      `useUserInfos doit être utilisé dans la partie authentifiée de l'application`
    );
  }
  return user;
}

export { AuthProvider, useAuth, useUserInfos };
