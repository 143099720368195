import { UserDetailResponse } from 'api/account';
import { Role, roles, rolesAuthorities } from 'enum/roles';
import { knownTimezones, type TimeZone } from 'enum/timezones';
import { optionsNbLignesTableau } from 'utils/lignesTableauUtils';

import type { Authority } from 'enum/authorities';
import type { Module } from 'enum/modules';

export type Client = {
  id: number;
  libelle: string;
  modules: string;
  listModules: Module[];
  nbLicences: number;
  nbUtilisateurs: number;
  constructeur: boolean;
  dateCreation: number;
  desactive: boolean;
  dateDernierChangementEtat: number;
};

export type UserClient = {
  id: number;
  libelle: string;
  desactive: boolean;
  modules: Module[];
  nbPolesVisibles: number;
  nbPolesActifs: number;
};

export type UserPreferences = {
  nbLignesTableau: number;
  timezone: TimeZone;
};

export type User = {
  email: string;
  nom: string;
  prenom: string;
  role: Role;
  preferences: UserPreferences;

  isConstructeur: boolean;
  isEquans: boolean;
  isMultiClients: boolean;
  canSeeDataOfMultipleClients: boolean;
  clientsVisibles: UserClient[];

  /**
   * Renvoie la timezone renseignée dans les préférences utilisateur si elle existe, sinon celle du navigateur
   */
  getTimezone: () => string;

  /**
   * Teste si l'utilisateur a visibilité sur un client possèdant le module voulu
   *
   * Un utilisateur constructeur voit les des données de tous les clients comme s'ils possédait tous les modules
   */
  hasModule: (module?: Module) => boolean;

  /**
   * Teste si l'utilisateur possède un droit
   * @param {string} authority le droit à tester (utiliser l'enum authorities)
   * @returns true si l'utilisateur possède le droit, false sinon
   * @see authorities
   * @see roles
   */
  hasAuthority: (authority?: Authority | null) => boolean;
};

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const createUserInfos = (
  user: UserDetailResponse | undefined
): User | null => {
  if (!user) {
    return null;
  }

  return {
    email: user.email,
    nom: user.nom,
    prenom: user.prenom,
    role: user.role,

    clientsVisibles: user.clients.filter((c) => !c.desactive),
    preferences: createPreferences(user.preferences),

    isConstructeur: isRoleConstructeur(user.role),
    isEquans: isRoleEquans(user.role),
    isMultiClients: isRoleConstructeur(user.role) || user.clients.length > 1,
    canSeeDataOfMultipleClients:
      isRoleConstructeur(user.role) ||
      user.clients.filter((c) => !c.desactive).length > 1,

    getTimezone() {
      return this.preferences.timezone !== knownTimezones.NEAREST
        ? this.preferences.timezone
        : Intl.DateTimeFormat().resolvedOptions().timeZone;
    },

    hasAuthority(authority?: Authority | null) {
      return !authority || rolesAuthorities[this.role].includes(authority);
    },

    hasModule(module) {
      return (
        this.isConstructeur ||
        !module ||
        this.clientsVisibles.some((c) => c.modules.some((m) => m === module))
      );
    },
  };
};

const createPreferences = (prefs: UserDetailResponse['preferences']) => {
  const nbLignesTableau =
    optionsNbLignesTableau.find((x) => x.value === prefs?.nbLignesTableau)
      ?.value ?? 25;

  const timezone =
    Object.values(knownTimezones).find((tz) => tz === prefs?.timezone) ??
    knownTimezones.NEAREST;

  return {
    nbLignesTableau,
    timezone,
  };
};

const isRoleEquans = (role: Role) =>
  isRoleConstructeur(role) || role === roles.CLIENT_ADMINISTRATEUR;

export const isRoleConstructeur = (role: Role) =>
  role === roles.CONSTRUCTEUR_ADMINISTRATEUR ||
  role === roles.CONSTRUCTEUR_CONSULTATION;

export const hasLimitedPoleVisibility = (role: Role) =>
  role === roles.CLIENT_SIMPLE || role === roles.CLIENT_CONSULTATION;
