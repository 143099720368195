export type Authority = keyof typeof authorities;

/**
 * Droits permmettant les accès aux différentes fonctions de l'appli
 * @readonly
 * @enum {string}
 */
export const authorities = {
  ADMINISTRATION_CLIENTS: 'ADMINISTRATION_CLIENTS',
  ADMINISTRATION_UTILISATEURS: 'ADMINISTRATION_UTILISATEURS',
  SUPER_ADMIN: 'SUPER_ADMIN',
  WRITE: 'WRITE',
} as const;
