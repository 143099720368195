import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';

import { AuthProvider } from './auth-context';
import { SnackbarCloseButton } from 'components/snackbar/SnackbarCloseButton';

type ProvidersProps = React.PropsWithChildren<{
  queryClient: QueryClient;
  withQueryDevTools: boolean;
}>;
const AppProviders = ({
  children,
  queryClient,
  withQueryDevTools,
}: ProvidersProps): JSX.Element => {
  return (
    <SnackbarProvider
      maxSnack={3}
      action={(id) => <SnackbarCloseButton id={id} />}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
    >
      <QueryClientProvider client={queryClient}>
        {withQueryDevTools && (
          <ReactQueryDevtools buttonPosition="bottom-left" />
        )}
        <AuthProvider>{children}</AuthProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  );
};

export default AppProviders;
