/* eslint-disable sort-keys-fix/sort-keys-fix */

export type TimeZone = keyof typeof knownTimezones;
// Trié par offset par rapport à UTC
export const knownTimezones = {
  NEAREST: 'NEAREST',
  'America/Los_Angeles': 'America/Los_Angeles',
  'America/Denver': 'America/Denver',
  'America/Chicago': 'America/Chicago',
  'America/New_York': 'America/New_York',
  'Europe/London': 'Europe/London',
  'Europe/Paris': 'Europe/Paris',
} as const;
