import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from 'i18n/locales/en.json';
import fr from 'i18n/locales/fr.json';

const debug = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

i18n
  // detect user language
  // https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // passes i18n down to react-i18next
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug,
    // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    fallbackLng: 'en',
    interpolation: {
      // react already safes from xss
      escapeValue: false,
    },
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
    },
    supportedLngs: ['fr', 'fr-FR', 'en', 'en-US'],
  })
  .catch(() => console.log('Erreur de chargement des traductions'));

export default i18n;
